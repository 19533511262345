.select__dropdown-scroll {
  overflow-y: scroll;
}

.center__stat .float__fee {
  z-index: 10;
  bottom: 100%;
  top: inherit;
}
.row-group--align-start {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.row-group--justify-start {
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  &-important {
    -webkit-box-pack: flex-start !important;
    -ms-flex-pack: flex-start !important;
    justify-content: flex-start !important;
  }
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-around {
  justify-content: space-around;
}
.pb1 {
  padding-bottom: 1rem;
}
.pb2 {
  padding-bottom: 2rem;
}
.pt1 {
  padding-top: 1rem;
}
.pt2 {
  padding-top: 2rem;
}
.pt3 {
  padding-top: 3rem;
}
input,
button {
  &[type="submit"] {
    -moz-appearance: button;
    -webkit-appearance: button;
    &:disabled {
      opacity: 0.65;
    }
  }
}
button:disabled {
  opacity: 0.65;
}

.no-min-width {
  min-width: auto !important;
}

body,html{
	margin:0px;
	padding:0px;
}
body *{
	box-sizing:border-box;
	font-family:"Open Sans" ,sans-serif;
}
.box__switcher{
	display: inline-flex;
	align-items:center;
	justify-content:center;

	background-color:#FFFFFF;
	border-radius: 6px;
	box-shadow:6px 6px 30px 0px rgba(181,181,181,.25);
	margin-left: 0px;
	padding:4px;
	&>ul{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		list-style-type:none;
		padding:0px;
		margin:0px;
		&>li{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;
			&>a{
				display: inline-flex;
				align-items: center;
				justify-content: flex-start;

				padding-left: 8px;
				min-height: 32px;
				white-space: nowrap;
				text-decoration: none;
				font-size: 14px;
				line-break: 16px;
				color:#6C6C6C;
				font-weight: 600;
				text-decoration: none;
				border-radius:6px;
				padding-right:4px;
				transition:.3s ease all;
				font-weight:600;
				&:hover{
					background-color:#F0F7F7;
				}
				&:active , &:focus{
					box-shadow:0 0 0 0.08rem rgba(14, 125, 125, 0.5);
				}
				&.current{
					color:#0E7D7D;
					background-color:#F0F7F7;
					& span{
						background-color:#0E7D7D;
						border-radius: 6px;
						color:#FFFFFF;
					}
				}
				& span{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					margin-left: 15px;
					min-height: 24px;
					min-width: 32px;
					padding-left: 8px;
					background-color:#F6F6F6;
					border-radius: 6px;
					padding-right: 8px;
					font-size: 12px;
					line-height: 16px;
					font-weight: bold;
					color:#121212;
					transition:.3s ease all;
				}
			}
			&:last-child{
				margin-right: 0px;
			}
		}
	}
}
.tooltip__text__switcher{
	position: absolute;
	padding:12px;
	border:1px solid #DADADA;
	border-radius: 8px;
	background-color:#F6F6F6;
	z-index: 4;
	&>p{
		margin:0px;
		font-size: 12px;
		line-height: 18px;
		color:#6C6C6C;
		font-weight: 400;
	}
}
.semitransparent-btn{
	min-height: 34px;
	display: inline-flex;
	align-items:center;
	justify-content:center;
	padding-left: 12px;
	padding-right: 12px;
	background-color:#E7F2F2;
	transition:.3s ease all;
	border-radius: 6px;
	font-size: 14px;
	line-height: 18px;
	color:#000000;
	font-weight: 600;
	text-decoration: none;
	&:hover{
		background-color:#c9e7e7;
	}
	& span{
		display: inline-flex;
		margin-right: 8px;
	}
}
.main-regular-btn{
	min-height: 40px;
	padding-left: 12px;
	padding-right: 12px;
	background-color:#0E7D7D;
	border-radius: 8px;
	border:1px solid #0E7D7D;
	font-size: 14px;
	line-height: 18px;
	text-decoration: none;
	font-weight: 600;
	color:#FFFFFF;
	padding-bottom: 2px;
	box-shadow:0px 4px 10px rgba(138,138,138,.2);
	transition:.3s ease all;
	&:hover{
		background-color:#0d6e6e;
	}
	& span{
		position: relative;
		top:1px;
		display: inline-flex;
		margin-right: 12px;
		width: 24px;
		height: 24px;
	}
}

.isOpened img {
  transform: rotate(180deg) !important;
	transition: transform 0.5s ease;
}

.ui-resizable-e{
	background-color: transparent;
	width: 1.5px;
	height: 100%;
	background-color:#cecece;
	right: 0px;
	top: 0px;
}

.ui-resizable-handle{
	position: absolute;
	font-size: 0.1px;
	display: block;
	touch-action: none;
	cursor:url('../images/resize.png'), auto;
}

.ui-resizable {
	position: relative;
}

.preview {
	background-color: #E5E5E5 !important;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 8px;
	border: 1px solid #D9D9D9;
	position: fixed;
	pointer-events: none;
	left: 0;
	top: 0;
}

.preview-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.preview-wrapper p {
	white-space: nowrap;
	margin: 0px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	color: #444444;
}

.resizer {
	display: block;
	background: black;
	width: 1.5px;
	height: 100%;
	background-color:#cecece;
	position: absolute;
	right: 0;
	top: 0;
	transform: translateX(50%);
	z-index: 1;
	touch-action:none;

	&.isResizing {
		background: black;
	}
}

.footer-wrapper {
  display: flex;
  align-items: center;
}

.skeleton-wrapper {
	width: 90%;
	margin-left: 0.5rem;
}

.main__content--wrapper{
	//width: calc(100% - 200px);
	margin-left: auto;
	//min-height: calc(100vh - 64px);
	background-color:#EBEBEF;
	padding:16px 24px;

	& .content__box{

		& .elem__table{
			
			& .row{
				transition:.3s ease all;
			}
			& .row.active{
				& td{
					background-color:#CFE9FF;
				}
			}
			& .row.highlight{
				& td{
					background-color:#ECF1F1;
				}
			}
			& .accordion__head{
				&>span{
					transition:.3s ease all;
				}
			}
			&.opened{
				& .accordion__head{
					&>span{
						transform:rotate(180deg);
					}
				}
			}
			margin-bottom: 8px;
			& .tbl__elem{
				padding:8px 16px;
				& .table__inner--box{
					width: 100%;
					overflow-x:auto;
					&::-webkit-scrollbar {
					  width: 4px;
					  height: 4px;
					}
					 
					// &::-webkit-scrollbar-track {
					// }
					 
					&::-webkit-scrollbar-thumb {
					  background-color:#0E7D7D;
					  border-radius: 250px;
					}
					& .row{
						&:hover{
							background-color:#CFE9FF!important;
							& .column{
								background-color:#CFE9FF!important;
							}
						}
						transition:none!important;
					}
					& .row{
						transition:none!important;
						display: flex;
						// width: 100% !important;
						align-items: center;
						justify-content: flex-start;
						margin-left: 0px!important;
						margin-right: 0px!important;
						&:nth-child(even){
							background-color:#F2F3F6;
							& .column{
								background-color:#F2F3F6;
							}
						}
						&:nth-child(odd){
							background-color:#fff;
							& .column{
								background-color:#fff;
							}
						}
						&.regular{
							& .column{
								/* width: 100%; */
								padding:8px;
								height: 40.5px;
								display: flex;
								align-items:center;
								justify-content:flex-start;
								// flex-basis: 0;
								// flex: 1 1 0px;
								&:nth-child(1){
									border-top-left-radius: 8px;
									border-bottom-left-radius: 8px;
									padding-left: 16px;
								}
								&:last-child{
									padding-right: 16px;
									border-top-right-radius: 8px;
									border-bottom-right-radius: 8px;
								}
							}
						}
						&.main{
							text-align: left;
							background-color:#E5E5E5!important;
							border-top-left-radius: 8px;
							border-bottom-left-radius: 8px;
							border-top-right-radius: 8px;
							border-bottom-right-radius: 8px;
							margin-bottom: 4px;
							& .column{
								background-color:#E5E5E5!important;
								/* width: 100%; */
								display: flex;
								align-items:center;
								justify-content:flex-start;
								// flex-basis: 0;
								// flex: 1 1 0px;
								& .main__col--wrap{
									display: flex;
									align-items:center;
									justify-content:space-between;
									width: 100%;
								}
								padding:8px;
								&:hover .controls{
									opacity:1;
								}
								& .controls{
									//margin-left: 60px;
									display: inline-flex;
									align-items:center;
									justify-content:center;
									opacity:0;
									transition:.3s ease all;
									&>a{
										display: inline-flex;
										transition:.3s ease all;
										opacity:.7;
										&.move__btn{
											cursor:grab;
										}
										&:hover{
											opacity:1;
										}
										& img{
											min-width: 16px;
											max-width: 16px;
										}
									}
								}
								&:nth-child(1){
									border-top-left-radius: 8px;
									border-bottom-left-radius: 8px;
									padding-left: 16px;
								}
								&:last-child{
									padding-right: 16px;
									border-top-right-radius: 8px;
									border-bottom-right-radius: 8px;
								}
							}
							& .column p{
								white-space: nowrap;
								margin:0px;
								font-size: 12px;
								line-height: 16px;
								font-weight: 600;
								color:#444444;
							}
						}
					}
				}
				& .table__box{
					width: 100%;
					border:1px solid #D9D9D9;
					border-radius: 8px;
					padding:2px;
					
					& .nobreak{
						white-space: nowrap; 
					}
					& .status{
						display: inline-flex;
						&>span{
							font-size: 12px;
							line-height: 16px;
							font-weight: 600;
							padding:2px 8px;
							white-space: nowrap;
							border-radius: 250px;
							display: inline-flex;
							align-items:center;
							justify-content:flex-start;

						}
						& img{
							min-width: 10px;
							max-width: 10px;
							display: inline-flex;
						}
						&>.processing{
							color:#D59800;
							background-color:#FDF6E6;
						}
						&>.correction__needed{
							color:#D59800;
							background-color:#FDF6E6;
						}
						&>.registrations{
							color:#C700E7;
							background-color:#FAE6FD;
						}
						&>.looking_worker{
							color:#C700E7;
							background-color:#FAE6FD;
						}
						&>.completed{
							color:#0E7D7D;
							background-color:#E6FAF0;
						}
						&>.finished{
							color:#0070CD;
							background-color:#ECF4FA;
						}
						&>.waiting{
							color:#E27A00;
							background-color:#FBE7D5;
						}
						&>.waiting__approval{
							color:#E27A00;
							background-color:#FBE7D5;
						}
						&>.pickedup{
							color:#523FC5;
							background-color:#EEECFA;
						}
						&>.to__be__picked__up{
							color:#523FC5;
							background-color:#EEECFA;
						}
						&>.new__job__offer{
							color:#523FC5;
							background-color:#EEECFA;
						}
						&>.assigned{
							color:#0E7D7D;
							background-color:#E6FAF0;
						}
						&>.assigned__to__you{
							color:#0E7D7D;
							background-color:#E6FAF0;
						}
						&>.corrections{
							color:#CA9000;
							background-color:#FDF6E6;
						}
						&>.cancelled{
							color:#FF3232;
							background-color:#FFEBEB;
							& img{
								min-width: 10px;
								max-width: 10px;
								display: inline-flex;
							}
						}
						&>.not__assigned{
							color:#717171;
							background-color:#ECECEC;
							& img{
								display: inline-flex;
								min-width: 10px;
								max-width: 10px;
							}
						}
						&>.not__assigned__to__you{
							color:#0E7D7D;
							background-color:#E6FAF0;
						}
					}
					& .regular-text{
						margin:0px;
						font-size: 12px;
						line-height: 16px;
						color:#444444;
						font-weight: 600;
					}
					& .created__by{
						display: inline-flex;
						align-items:center;
						justify-content:flex-start;
						font-size: 12px;
						line-height: 16px;
						color:#2D2D2D;
						font-weight: 600;
						white-space: nowrap;
						&>span{
							display: inline-flex;
							margin-right: 8px;
							& img{
								min-width: 15px;
								max-width: 15px;
								min-height: 15px;
								max-height: 15px;
								border-radius: 250px;
							}
						}
					}
					& .client{
						display: inline-flex;
						align-items:center;
						justify-content:flex-start;
						&>span{
							display: inline-flex;
							min-height: 16px;
							max-height: 16px;
							cursor:pointer;
							& img{
								min-width: 16px;
								max-width: 16px;
							}
						}
						&>a{
							font-size: 12px;
							line-height: 16px;
							font-weight: 600;
							color:#188EF0;
							text-decoration: underline;
							width : auto;
							max-width: 140px;
						  overflow:hidden;
						  display:inline-block;
						  text-overflow: ellipsis;
						  white-space: nowrap;
						  margin-right: 8px;
						}
					}
					& .languages{
						display: flex;
						align-items:center;
						//justify-content:space-between;
						width: auto;
						& ul{
							padding:0px;
							list-style-type: none;
							margin:0px;
							display: inline-flex;
							align-items:center;
							justify-content:flex-start;
							&>li{
								display: inline-flex;
								margin-right: 8px;
								& img{
									min-width: 12px;
									max-width: 12px;
									border-radius: 4px;
								}
								&:last-child{
									margin-right: 0px;
								}
							}
						}
						&>p{
							font-size: 12px;
							line-height: 16px;
							color:#444444;
							font-weight: 600;
							margin-right: 14px;
							white-space: nowrap;
							margin:0px;
							margin-right: 8px;
						}
					}
					& .services__list{
						display: flex;
						align-items:flex-start;
						justify-content:center;
						flex-direction:column;
						&>p{
							display: inline-flex;
							margin-bottom: 5px;
							margin-top: 0px;
							font-size: 12px;
							line-height: 16px;
							color:#787878;
							font-weight: 600;
							& span{
								display: inline-flex;
								min-width: 16px;
								max-width: 16px;
								margin-right: 8px;
								& img{
									max-width: 100%;
								}
							}
							&:last-child{
								margin-bottom: 0px;
							}
						}
					}
					& .profile__btn{
						display: inline-flex;
						text-decoration: none;
						color:#2D2D2D;
						font-size: 12px;
						line-height: 16px;
						font-weight: 600;
						white-space: nowrap;
						text-decoration: underline;
						& span{
							display: inline-flex;
							margin-right: 8px;
							align-items:center;
							justify-content:center;
							& img{
								min-height: 15px;
								max-height: 15px;
								min-width: 15px;
								max-width: 15px;
								border-radius: 250px;
								object-fit:cover;
							}
						}
					}
					& .id{
						color:#0E7D7D;
						font-size: 12px;
						line-height: 18px;
						font-weight: 600;
						text-decoration: none;
					}
				}
				
			}
			& .accordion__head{
				display: flex;
				align-items:center;
				justify-content:flex-start;
				padding:4px 16px;
				background-color:#F5F5F5;
				min-height: 32px;
				cursor:pointer;
				&>p{
					margin:0px;
					font-size: 14px;
					line-height: 18px;
					color:#787878;
					font-weight: 600;
					& span{
						min-width: 31px;
						min-height: 24px;
						display: inline-flex;
						align-items:center;
						justify-content:center;
						background-color:#FFFFFF;
						border-radius: 6px;
						font-size: 12px;
						line-height: 16px;
						font-weight: bold;
						color:#787878;
						margin-left: 12px;
					}
				}
				&>span{
					display: inline-flex;
					align-items:center;
					justify-content:center;
					margin-right: 16px;
					position: relative;
					top:-1px;
					transition:.3s ease all;
				}
			}
			&:last-child{
				margin-bottom: 0px;
			}
		}
		border-radius: 8px;
		background-color:#FFFFFF;
		box-shadow:0px 4px 10px rgba(138,138,138,.2);
		width: 100%;
		& .btns{
			padding-top: 16px;
			padding-left: 16px;
			padding-right: 16px;
			padding-bottom: 8px;
			& .inner{
				margin-top: -8px;
				margin-left: -8px;
				display: flex;
				align-items:center;
				justify-content:flex-start;
				flex-wrap:wrap;
				&>a{
					margin:8px;
				}
			}
		}
	}
	& .top__controls{
		display: flex;
		align-items:center;
		justify-content:space-between;
		margin-bottom: 16px;
		& .right__side{
			display: flex;
			align-items:center;
			justify-content:flex-end;
			& .filter__btn{
				display: inline-flex;
				margin-right: 16px;
				&>a{
					display: inline-flex;
					align-items:center;
					justify-content:center;
					min-width: 40px;
					max-width: 40px;
					min-height: 40px;
					box-shadow:0px 4px 10px rgba(138,138,138,.2);
					max-height: 40px;
					background-color:#FFFFFF;
					border-radius: 8px;
					text-decoration: none;
					transition:.3s ease all;
					&:hover{
						background-color:#0E7D7D;
						& svg path{
							fill:#fff;
						}
					}
					& svg path{
						transition:.3s ease all;
					}
				}
			}
			& .btns{
				display: inline-flex;
				&:last-child{
					margin-right: 0px;
				}
				&>a{
					display: inline-flex;
					align-items:center;
					justify-content:center;
					white-space: nowrap;
					//margin-right: 15px;
					&:last-child{
						margin-right: 0px;
					}
				}
				&:last-child{
					margin-right: 0px;
				}
			}
		}
		& .left__side{
			display: flex;
			align-items:center;
			justify-content: space-between;
			margin-right: 15px;
			width: 100%;
			& .search{
				max-width: 240px;
				& form{
					width: 100%;
					display: flex;
					align-items:center;
					justify-content:flex-start;
					position: relative;
					& button{
						position: absolute;
						right: 10px;
						display: inline-flex;
						padding:0px;
						align-items:center;
						justify-content:center;
						background-color: transparent;
						border:0px;
						cursor:pointer;
						outline:none;
						&:hover svg path{
							fill:#0E7D7D;
						}
						& svg path{
							transition:.3s ease all;
						}
					}
					&>input{
						width: 100%;
						height: 40px;
						border:0px;
						border-radius: 6px;
						outline:none;
						font-size: 14px;
						line-height: 18px;
						font-weight: 600;
						color:#000;
						padding-left: 16px;
						padding-right: 48px;
					}
				}
			}
			&>h6{
				margin:0px;
				white-space: nowrap;
				font-size: 20px;
				line-height: 32px;
				color:#0E7D7D;
				font-weight: bold;
				margin-right: 40px;
				&:last-child{
					margin-right: 0px;
				}
			}
		}
	}
}

.container__check {
  display: inline-flex;
  align-items:center;
  cursor:pointer;
  justify-content:flex-start;
}
.container__check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: relative;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  border-radius: 2px;
  border:1px solid #0E7D7D;
  background-color:#FFFFFF;
}

.container__check input:checked ~ .checkmark {
  background-color: #0E7D7D;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container__check input:checked ~ .checkmark:after {
  display: block;
}
.container__check .checkmark:after {
  position: absolute;
  content:"";
  left: 50%;
  top:50%;
  transform:translate(-50% ,-50%);
  width: 100%;
  height: 100%;
  background:url('../images/checkwhite.svg');
  background-repeat: no-repeat;
  background-size: 6px;
  background-position: center;
}
.client__details{
	position: absolute;
	z-index: 3;
	min-width: 295px;
	max-width: 295px;
	padding:8px 12px;
	background-color:#fff;
	border-radius: 8px;
	box-shadow:0px 0px 20px 0px rgba(0,0,0,.15);
	padding-bottom: 14px;
	display: none;
	& .inner{
		&  .details{
			&>.elem{
				margin-bottom: 8px;
				display: inline-flex;
				align-items:center;
				justify-content:flex-start;
				position: relative;
				width: 100%;
				&>a{
					position: absolute;
					right: 0px;
					display: inline-flex;
					align-items:center;
					justify-content:center;
				}
				&>span{
					display: inline-flex;
					min-width: 44px;
					max-width: 44px;
					margin-right: 8px;
					font-size: 12px;
					line-height: 18px;
					color:#787878;
				}
				&>p{
					margin:0px;
					font-size: 12px;
					line-height: 18px;
					font-weight: 600;
					color:#787878;
				}
				&:last-child{
					margin-bottom: 0px;
				}
			}
		}
		&>.top{
			display: flex;
			align-items:center;
			justify-content:space-between;
			margin-bottom: 2px;
			&>p{
				margin:0px;
				font-size: 12px;
				line-height: 18px;
				font-weight: 600;
				color:#000000;
			}
			&>.copy{
				min-width: 32px;
				max-width: 32px;
				min-height: 32px;
				max-height: 32px;
				border-radius: 4px;
				transition:.3s ease all;
				&:hover{
					background-color:#0E7D7D;
					& svg path{
						fill:#fff;
					}
				}
				& svg path{
					transition:.3s ease all;
				}
				display: inline-flex;
				align-items:center;
				justify-content:center;
			}
		}
	}
	&:before{
		content:"";
		position: absolute;
		left: 50%;
		transform:translateX(-50%);
		width: 27px;
		height: 12px;
		background:url('../images/polygon.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		bottom:-11px;
		z-index: 2;
	}
}
.client__details .inner .details>.elem{
	padding-right: 25px;
}

.client__details .inner .details>.elem>p{
	//word-break:break-all;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 8px;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box tr:hover td{
	background-color:#CFE9FF;
}
.main__content--wrapper .content__box .btns{
	display: none;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box table tr td .profile__btn{
	position: relative;
	top:2px;
}

.main__content--wrapper .content__box .elem__table .tbl__elem .table__box table tr:nth-child(odd){
	background-color:#F2F3F6;
}
.tbl__elem .row.checked , .tbl__elem .row.checked .column{
	background-color:#B2F3F0!important;
}
.show__more{
	padding:8px 8px;
	&>a{
		display: inline-flex;
		text-decoration: none;
		font-size: 12px;
		line-height: 18px;
		color:#0E7D7D;
		font-weight: 600;
		transition:.3s ease all;
		&:hover{
			color:#0d6e6e;
		}
	}
}
.main__content--wrapper .content__box{
	border-top-left-radius: 0px;
}
.tabs__switcher{
	& ul{
		padding:0px;
		list-style-type: none;
		margin:0px;
		display: flex;
		align-items:center;
		justify-content:flex-start;
		&>li{
			margin-right: 8px;
			&>a{
				display: inline-flex;
				align-items:center;
				justify-content:center;
				min-height: 34px;
				font-size: 12px;
				line-height: 18px;
				color:#9E9E9E;
				font-weight: 600;
				text-decoration: none;
				background-color:#F5F5F7;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				padding-left: 24px;
				padding-right: 24px;
				transition:.3s ease all;
				&:hover{
					background-color:#fff;
					color:#0E7D7D;	
				}
				&.current{
					background-color:#fff;
					color:#0E7D7D;
				}
			}
			&:last-child{
				margin-right: 0px;
			}
		}
	}
}
.main__controls{
	display: flex;
	justify-content:space-between;
	align-items:center;
	min-height: 66px;
	padding-right: 16px;
	& .filters{
		margin-left: auto;
		display: flex;
		align-items:center;
		justify-content:flex-end;
		& .filter__settings{
			margin-left: 24px;
			& .settings__{
				position: relative;
				& .settings__dropdown{
					z-index: 2;
					position: absolute;
					right: 0px;
					padding:16px;
					border-radius: 8px;
					background-color:#fff;
					border:1px solid #DADADA;
					& ul{
						padding:0px;
						list-style-type: none;
						margin:0px;
						&>li{
							&:last-child{
								border-bottom:0px;
							}
							& a{
								white-space: nowrap;
								text-decoration: none;
								font-size: 14px;
								line-height: 18px;
								color:#1D1C19;
								transition:.3s ease all;
								&:hover{
									color:#0E7D7D;
								}
							}
							padding-bottom: 12px;
							margin-bottom: 12px;
							border-bottom:1px solid #DADADA;
							&:last-child{
								margin-bottom: 0px;
								padding-bottom: 0px;
							}
						}
					}
				}
				&>a{
					display: inline-flex;
					align-items:center;
					justify-content:center;
					min-width: 80px;
					min-height: 32px;
					border-radius: 8px;
					border:1px solid #0E7D7D;
					text-decoration: none;
					transition:.3s ease all;
					&.opened{
						background-color:#E7F2F2;
						& span{
							transform:rotate(180deg);
						}
					}
					& span{
						transition:.3s ease all;
						display: inline-flex;
						margin-left: 8px;
					}
				}
			}
		}
		&>.reset__filter{
			color:#0E7D7D;
			font-size: 14px;
			line-height: 18px;
			font-weight: 600;
			text-decoration: none;
			transition:.3s ease all;
			&:hover{
				opacity:.7;
			}
		}
		&>p{
			margin:0px;
			font-size: 14px;
			line-height: 18px;
			color:#777777;
			font-weight: 400;
		}
		&>.spacer{
			display: inline-flex;
			margin:0px 15px;
			min-width: 1px;
			min-height: 16px;
			border-radius: 56px;
			background-color:#777777;
		}
	}
}

.filter__rows{
	& .custom__column{
		padding-left: 32px;
		padding-bottom: 80px;
		padding-right: 32px;
		width: 100%;
		position: relative;
		max-width: 100%;
		& .inn{
			max-width: 290px;
			position: relative;
		}
		& a{
			position: absolute;
			right: 2px;
			height: 40px;
			padding-left: 16px;
			padding-right: 16px;
			white-space: nowrap;
			background-color:#0E7D7D;
			top:50%;
			transform:translateY(-50%);
			border-radius: 6px;
			font-size: 14px;
			line-height: 18px;
			color:#FFFFFF;
			font-weight: 600;
			display: inline-flex;
			align-items:center;
			justify-content:center;
			transition:.3s ease all;
			&:hover{
				background-color:#0d6e6e;
			}
			text-decoration: none;
			& span{
				display: inline-flex;
				margin-left: 8px;
			}
		}
		& input{
			height: 44px;
			border:1px solid #D4D4D4;
			border-radius: 8px;
			padding-left: 16px;
			padding-right: 90px;
			outline:none;
			font-size: 14px;
			line-height: 18px;
			color:#777777;
			font-weight: 400;
			width: 100%;
		}
	}
	& .filter__element{
		margin-bottom: 24px;
		padding-left: 32px;
		padding-right: 32px;
		& .grid{
			display: grid;
			grid-template-columns:repeat(4,1fr);
			grid-column-gap:24px;
			grid-row-gap:12px;

			&>.elem{
				& .container__check{
					display: flex;
					align-items:center;
					justify-content:flex-start;
					& .checkmark{
						min-width: 17px;
						max-width: 17px;
						min-height: 17px;
						max-height: 17px;
						border:2px solid #0E7D7D;
						margin-right: 12px;
						&:after{
							background-size: 10px;
						}
					}
					&>p{
						margin:0px;
						font-size: 14px;
						line-height: 24px;
						font-weight: 600;
						color:#1D1C19;
					}
				}
			}
		}
		&>p{
			margin-top: 0px;
			margin-bottom: 15px;
			font-size: 18px;
			line-height: 34px;
			color:#1D1C19;
			font-weight: 600;
		}
		&:last-child{
			margin-bottom: 0px;
		}
	}
	&>.top__info{
		display: flex;
		justify-content:flex-end;
		align-items:center;
		padding:0px 16px;
		margin-bottom: 10px;
		&>p{
			margin:0px;
			margin-right: 24px;
			font-size: 14px;
			line-height: 18px;
			color:#777777;
			font-weight: 600;
		}
		&>a{
			display: inline-flex;
			align-items:center;
			justify-content:center;
			min-height: 38px;
			padding-left: 16px;
			padding-right: 16px;
			border:1px solid #0E7D7D;
			border-radius: 6px;
			text-decoration: none;
			font-size: 14px;
			line-height: 22px;
			font-weight: 600;
			color:#0E7D7D;
			background-color:rgba(14,125,125,.1);
			transition:.3s ease all;
			&:hover{
				opacity:.7;
			}
		}
	}
}
.filter__rows .filter__element .grid>.elem .container__check{
	align-items:flex-start;
}
.filter__rows .filter__element .grid>.elem .container__check>p{
	position: relative;
	top:-4px;
}
.main__controls .filters>p{
	white-space: nowrap;
}
.main__controls .filters>.reset__filter{
	white-space: nowrap;
}
.main__content--wrapper .content__box .elem__table:last-child.opened .accordion__head{
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.main__content--wrapper .content__box .elem__table:last-child .accordion__head{
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__inner--box .row.main .column.drop-hover {
	background-color:#CFE9FF!important;
	& *{
		opacity:0!important;
		
	}
}
.ui-draggable-dragging{
	z-index: 3!important;
}


.ui-resizable-e{
	background-color: transparent;
	width: 1px;
	background-color:#cecece;
	right: 0px;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__inner--box .row{
	justify-content:flex-start;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .client{
	width: auto;
	max-width: 100%;
	//position: relative;
	padding-right: 12px;
}

.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .profile__btn span{
	position: relative;
	top:3px;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .services__list{
	display: block;
	width: 100%;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .services__list{
	margin-bottom: -3px;
	position: relative;
	top:1px;
	display: block;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .regular-text{
	overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
     max-width: 100%;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .status > span{
	display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow:hidden;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .status > .canceled img{
	position: relative;
	top:1px;
	margin-left: 2px;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .status{
	display: block;
	width: 100%;
	// position: relative;
	top:2px;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .created__by > span{
	position: relative;
	top:2px;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .profile__btn , .main__content--wrapper .content__box .elem__table .tbl__elem .table__box .created__by{
    max-width: 100%;
    position: relative;
    top:-2px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .services__list > p{
	display: inline-block;
	position: relative;
	top:-2px;
	overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
	&>span{
		position: relative;
		top:3px;
	}
}
.main__content--wrapper .content__box .elem__table .tbl__elem .table__box .id{
	overflow: hidden;
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 1280px){
	.filter__rows .filter__element .grid{
		grid-template-columns:repeat(3 ,1fr);
	}
}
@media (max-width: 1200px){
	.main__content--wrapper{
		width: 100%;
	}
}
@media (max-width: 991px){
	.filter__rows .custom__column{
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 25px;
	}
	.filter__rows .filter__element{
		margin-bottom: 15px;
	}
	.filter__rows .filter__element .grid{
		grid-template-columns:repeat(2 ,1fr);
	}
	.filter__rows .filter__element{
		padding-left: 16px;
		padding-right: 16px;
	}
}
.filter__rows>.top__info>p{
	text-align: right;
}
@media (max-width: 767px){
	.main__controls{

		flex-direction:column;
	}
	.main__content--wrapper .content__box .btns{
		margin-top: -14px;
		width: 100%;
		padding-right: 0px;
		padding-bottom: 0px;
	}
	.main__controls .filters{
		flex-wrap:wrap;
	}
	.main__controls{
		margin-bottom: 12px;
		padding-top: 12px;
	}
	.main__controls .filters .filter__settings{
		margin-left: 0px;
		margin-top: 8px;
		width: 100%;
	}
	.main__controls .filters{
		max-width: 100%;
		width: 100%;
		padding-left: 15px;
	}
	.main__controls .filters .filter__settings .settings__{
		max-width: 80px;
		margin-left: auto;
	}
	.client__details{
		min-width: 240px;
		max-width: 240px;
	}
	.main__content--wrapper{
		padding:16px;
	}
	.main__content--wrapper .top__controls{
		flex-direction:column;
	}
	
	.main__content--wrapper .top__controls .left__side{
		width: 100%;
	}
	.main__content--wrapper .top__controls .right__side{
		width: 100%;
		margin-top:12px;
	}
	.main__content--wrapper .top__controls .left__side{
		flex-direction:column;
		&>h6{
			margin-right: 0px;
			margin-bottom: 8px;
			width: 100%;
		}
	}
	.main__content--wrapper .top__controls .left__side .search{
		max-width: 100%;
	}
}
@media (max-width: 640px){
	.filter__rows .filter__element .grid{
		grid-template-columns:1fr;
		grid-row-gap:7px;
	}
}
@media (max-width: 480px){
	.filter__rows>.top__info{
		flex-direction:column;
		align-items:center;
		justify-content:center;
		&>p{
			margin-right: 0px;
			margin-bottom: 10px;
		}
		&>a{
			width: 100%;
		}
	}
}
.el__pick--content {
  & .select__label {
    & span {
      display: flex;
      width: 100%;
      text-align: left;
    }
  }
}
.modal__invoice {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 70px;
    right: calc(100% - 80px);
    transition: 0.4s ease all;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    transition: 0.4s ease all;
  }
  & .inner__invoice {
    & .head__invoice {
      max-width: 540px;
      & .more__invoice {
        margin-top: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        & > p {
          margin: 0px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 27px;
          & span {
            padding: 3px 3px;
            background-color: #f7f7f7;
            border-radius: 3px;
            font-size: 11px;
          }
          & > img {
            margin-right: 7px;
          }
        }
        & > a {
          text-decoration: underline;
          color: #0e7d7d;
          margin: 0px 30px;
          margin-top: 27px;
        }
        & .field__more {
          width: 100%;
          & > p {
            margin-bottom: 5px;
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: #2d2d2d;
          }
          max-width: 240px;
        }
      }
    }
    & .select {
      & .select__dropdown {
        margin-top: 0px;
      }
    }
    width: 100%;
    max-width: calc(100% - 100px);
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    right: 0px;
    transition: 0.4s ease all;
  }
}
.date__picker {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  & .elem__date {
    width: 100%;
    margin-right: 30px;
    &:last-child {
      margin-right: 0px;
    }
    & .datepicker {
      & .input-animation {
        &:before {
          content: none;
        }
      }
    }
    & > p {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #2d2d2d;
    }
  }
}
.double__invoice {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & .element__invoice {
    &:last-child {
      max-width: 440px;
      width: 100%;
    }
    & > h6 {
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 16px;
      color: #2d2d2d;
      font-weight: bold;
    }
    & > p {
      font-size: 14px;
      margin: 0px;
    }
    & .table__invoice {
      & .elem__table--invoice {
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        &:last-child {
          margin-bottom: 0px;
        }
        & > p {
          & span {
            font-weight: 600;
            display: inline-flex;
            margin-right: 7px;
          }
          margin: 0px;
          font-size: 14px;
          color: #2d2d2d;
          font-weight: 500;
        }
        justify-content: flex-start;
        & > span {
          font-size: 13px;
          color: grey;
          font-weight: bold;
          min-width: 140px;
        }
      }
      margin-top: 20px;
    }
  }
}
.invoice__total {
  max-width: 340px;
  margin-left: auto;
  margin-top: 40px;
  & .elem__radio {
    margin-bottom: 10px;
    & p {
      margin: 0px;
    }
  }
  & > .elem__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > span {
      font-size: 24px;
      font-weight: bold;
      color: #000;
    }
    & > p {
      margin: 0px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
    }
  }
}
.elem__radio [type="checkbox"]:checked,
.elem__radio [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.elem__radio [type="checkbox"]:checked + label,
.elem__radio [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  line-height: 20px;
  display: flex;
  color: #666;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.elem__radio [type="checkbox"]:checked + label:before,
.elem__radio [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: relative;
  left: 0;
  top: 0;
  width: 26px;
  height: 7px;
  border: 1px solid;
  display: inline-flex;
  border: 0px;
  border-radius: 3px;
  background: #cecece;
}
.elem__radio [type="checkbox"]:checked + label:after,
.elem__radio [type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #f1f1f1;
  position: absolute;
  top: auto;
  right: 15px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.elem__radio [type="checkbox"]:not(:checked) + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.elem__radio [type="checkbox"]:checked + label:after {
  opacity: 1;
  right: 0px;
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: #0e7d7d;
}
.information__invoice {
  width: 100%;
  margin-top: 50px;
  & .double__information {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
    & .elem__information {
      width: 100%;
      max-width: 250px;
      & > input {
        height: 40px;
        width: 100%;
        color: #444444;
        border-radius: 3px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffffff;
        border: 1px solid #dadada;
      }
      margin-right: 10px;
      & > p {
        font-size: 13px;
        color: grey;
        font-weight: bold;
        margin-bottom: 5px;
        margin-top: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  & .head__information {
    & > p {
      font-size: 16px;
      color: #000;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 0px;
    }
    & > h6 {
      margin: 0px;
      font-size: 21px;
      color: #000;
      font-weight: bold;
    }
    margin-bottom: 30px;
  }
  & .elem__radio {
    & [type="checkbox"]:checked + label:after {
      left: 12px !important;
    }
    & label {
      flex-direction: row !important;
      justify-content: flex-start !important;
      &:before {
        margin-right: 10px !important;
      }
      &:after {
        right: auto !important;
        left: 0px;
      }
    }
  }
}
.invoice__form {
  margin-top: 42px;
  & .more__content {
    margin-top: 20px;
    border-bottom: 1px solid #dadada;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > a {
      font-weight: bold;
      font-size: 14px;
      color: #0e7d7d;
      display: flex;
      align-items: center;
      justify-content: center;
      & span {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;

        max-height: 24px;
        border-radius: 50px;
        border: 2px solid #0e7d7d;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        & > img {
          max-width: 14px;
        }
      }
    }
  }
  & .table__content--wrapper {
    width: calc(100% + 60px);
    overflow-x: auto;
    padding-bottom: 400px;
    margin-bottom: -400px;
    padding-left: 30px;
    margin-left: -60px;
    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      height: 0px;
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    &::-webkit-scrollbar-thumb {
      background: #ff0000;
    }
    & .table__content {
      padding-left: 30px;
      & .table__content--head {
        min-width: 1440px;
      }
      & .table__content--main {
        position: relative;
        min-width: 1440px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & .el__table--content {
          padding: 10px 10px;
          padding-left: 0px;
          & > p {
            font-size: 14px;
            color: grey;
          }
          & > input[type="text"] {
            background-color: #ffffff;
            border-color: #dadada;
          }
          & .quantity__ {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            & > input {
              width: 100%;
              height: 40px;
              text-align: center;
              background-color: #f7f7f7;
            }
            & > a {
              &:nth-child(1) {
                display: inline-flex;
                min-width: 40px;
                max-width: 40px;
                min-height: 40px;
                max-height: 40px;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                background-color: rgba(14, 125, 125, 0.1);
                & span {
                  display: inline-flex;
                  background-color: rgba(14, 125, 125, 1);
                  min-width: 24px;
                  min-height: 4px;
                  border-radius: 3px;
                }
              }

              &:nth-child(3) {
                display: inline-flex;
                min-width: 40px;
                max-width: 40px;
                min-height: 40px;
                max-height: 40px;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                background-color: rgba(14, 125, 125, 0.1);
                position: relative;
                & span:nth-child(1) {
                  display: inline-flex;
                  background-color: rgba(14, 125, 125, 1);
                  min-width: 24px;
                  min-height: 4px;
                  border-radius: 3px;
                }
                & span:nth-child(2) {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  background-color: rgba(14, 125, 125, 1);
                  min-height: 24px;
                  min-width: 4px;
                  position: absolute;

                  border-radius: 3px;
                }
              }
            }
          }
        }
        & .draggable__icon {
          position: absolute;
          left: -25px;
          cursor: pointer;
          top: 17px;
          & > img {
            max-width: 16px;
          }
        }
      }
      & .el__table--content {
        & > h6 {
          margin: 0px;
          font-size: 14px;
          color: #000;
          font-weight: bold;
        }
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 7%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 13%;
        }
        &:nth-child(5) {
          width: 13%;
        }
        &:nth-child(6) {
          width: 10%;
        }
        &:nth-child(7) {
          width: 13%;
        }
        &:nth-child(8) {
          width: 13%;
        }
        &:nth-child(9) {
          padding-top: 18px;
          min-width: 30px;
          padding-right: 0px;
          text-align: center;
          & > .trash__button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      & .table__content--head {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  & .table__form {
    & > .table__form--head {
      margin-bottom: 20px;
      & > h2 {
        margin: 0px;
        font-size: 21px;
        color: #000;
        font-weight: bold;
      }
    }
  }
}
.modal__invoice .inner__invoice .head__invoice {
  max-width: 100%;
  & .invoice__triple {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    max-width: 100%;
    & > .select {
      margin-right: 30px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  & .date__picker {
    max-width: 640px;
  }
}
.el__pick--content .select__label {
  min-height: 40px;
  height: auto;
}
.el__pick--content .select__label span {
  display: flex;
  width: 100%;
  text-align: left;
  white-space: break-spaces;
  word-break: break-all;
  padding: 5px 0px;
}
.fully__disabled {
  display: none !important;
}
.select.fully__disabled--select .select__label:after {
  transform: none !important;
}
@media (max-width: 1200px) {
  .double__invoice .element__invoice:last-child {
    max-width: 320px;
  }
}

@media (max-width: 991px) {
  .modal__invoice .inner__invoice .head__invoice .invoice__triple {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
  .invoice__total {
    margin-top: 20px;
  }
  .information__invoice {
    margin-top: 20px;
  }
  .double__invoice {
    flex-direction: column;
    margin-top: 30px;
    & > .element__invoice {
      margin-bottom: 30px;
      max-width: 100%;
      width: 100%;
      &:last-child {
        max-width: 100%;
      }
    }
  }
}
.modal__invoice .inner__invoice .head__invoice .invoice__triple > .select {
  margin-right: 0px;
}
span {
  outline: none;
}
@media (max-width: 767px) {
  .modal__invoice .inner__invoice .head__invoice .invoice__triple {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
  .invoice__form .table__content--wrapper {
    width: calc(100% + 45px);
    margin-left: -30px;
  }
  .information__invoice .head__information > h6 {
    font-size: 19px;
  }
  .date__picker {
    width: 100%;
    flex-direction: column;
    & .elem__date {
      margin-right: 0px;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .modal__invoice .inner__invoice {
    padding: 25px 15px;
  }
  .modal__invoice .inner__invoice .head__invoice .more__invoice {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    & > a {
      margin: 0px;
      margin-top: 15px;
    }
    & > p {
      margin-top: 15px;
    }
  }
}
@media (max-width: 480px) {
  .modal__invoice .inner__invoice .head__invoice .more__invoice .field__more {
    max-width: 100%;
  }
  .information__invoice .head__information {
    margin-bottom: 15px;
  }
  .information__invoice .head__information > h6 {
    font-size: 17px;
  }
  .information__invoice .double__information {
    flex-direction: column;
    & > .elem__information {
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
    }
  }
  .double__invoice .element__invoice .table__invoice .elem__table--invoice {
    flex-direction: column;
    align-items: flex-start;
  }
  .modal__invoice .inner__invoice {
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }
  .modal__invoice > a {
    right: calc(100% - 50px);
  }
}

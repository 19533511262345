.comments__section {
  & .tabs__switcher {
    margin-top: 10px;
    & ul {
      & > li {
        & > a {
          white-space: nowrap;
          & > span {
            list-style: none;
            list-style-type: none;
            white-space: nowrap;
            box-sizing: border-box;
            font-family: 'Open Sans', sans-serif;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 12px;
            min-height: 20px;
            padding-left: 12px;
            padding-right: 12px;
            border-radius: 6px;
            font-size: 12px;
            line-height: 16px;
            transition: 0.3s ease all;
            font-weight: bold;
            color: #ffffff;
            background-color: #9e9e9e;
          }
          &.current {
            & > span {
              background-color: #0e7d7d;
            }
          }
        }
      }
    }
  }
}

.content__holder {
  width: 100%;
  padding: 16px;
  background-color: hwb(0 100% 0%);
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0px 4px 10px 0px rgba(138, 138, 138, 0.2);
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
}
.comments__content {
  min-height: 200px;
  position: relative;
  & .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & .comment__content {
    position: relative;
    min-height: 200px;
    transition: height 0.5s ease;
    &.hide {
      height: 0;
      overflow: hidden;
    }
    &.none {
      display: none;
    }
    margin-top: 8px;
    & .pick__comment--type {
      & ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-left: -4px;
        margin-top: -4px;
        & li {
          margin: 4px;
          & a {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 12px;
            padding-right: 12px;
            min-height: 32px;
            border-radius: 250px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            transition: 0.3s ease all;
            &.current {
              & span {
                border-color: transparent !important;
                & svg {
                  opacity: 1;
                }
              }
            }
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-width: 12px;
              max-width: 12px;
              min-height: 12px;
              max-height: 12px;
              border-radius: 250px;
              position: relative;
              transition: 0.3s ease all;
              margin-right: 8px;
              & svg {
                min-width: 16px;
                max-width: 16px;
                position: absolute;
                opacity: 0;
                transition: 0.3s ease all;
                left: 50%;
                transform: translate(-50%, -50%);
                top: 50%;
              }
            }
          }
        }
      }
    }
    & .submit {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & > button {
        width: auto;
        border-radius: 8px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    & .after {
      text-align: right;
      margin-top: 8px;
      margin-bottom: 15px;
      & > p {
        margin: 0px;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
        font-weight: 500;
        & span {
          font-weight: bold;
        }
      }
    }
  }
  & .add__new--comment {
    & > a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
      text-decoration: none;
      background-color: #f5f5f5;
      &.opened {
        & span {
          transform: rotate(180deg);
        }
      }
      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease all;
        margin-right: 16px;
      }
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      color: #000000;
      padding-left: 16px;
      min-height: 40px;
    }
  }
}
.add__new--comment {
  margin-top: 15px;
}

.comments__list {
  max-height: 350px;
  overflow-y: auto;
  & .center__text {
    width: fit-content;
    margin: 0 auto;

    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;

    color: #2d2d2d;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
  margin-top: 8px;
  & .elem__comment {
    padding: 8px 16px;
    margin-top: 8px;
    border: 1px solid #f5f5f5;
    border-left: 4px solid #f5f5f5;
    border-radius: 8px;
    &.comment__wrap {
      border-left-color: #066363;
    }
    &.poke__wrap {
      border-left-color: #2b0e7d;
    }
    &.technical_issue__wrap {
      border-left-color: #bc7e04;
    }
    &.linguistic_issue__wrap {
      border-left-color: #0050ea;
    }
    &.deadline__wrap {
      border-left-color: #b80000;
    }
    &.financials__wrap {
      border-left-color: #00701f;
    }
    & .main__part {
      margin-top: 16px;
    }
    & .top__part {
      display: flex;
      align-items: end;
      justify-content: space-between;
      & .tag {
        margin-left: 15px;
        display: flex;
        align-items: center;
        & > span {
          align-items: center;
          justify-content: center;
          padding: 4px 8px;
          border-radius: 6px;
          display: inline-flex;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
        }
        & .actions__wrapper {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          margin-right: 15px;
          & span {
            cursor: pointer;
          }
          & span:first-child {
            color: #0e7d7d;
          }
          & span:last-child {
            color: red;
          }
        }
      }
      & > .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & .time {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & > span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 2px 4px;
            font-size: 10px;
            line-height: 16px;
            color: #696969;
            font-weight: 500;
            border-radius: 4px;
            background-color: #e7e7e7;
          }
          & > p {
            margin: 0px;
            margin-right: 8px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            color: #282828;
            font-family: 'Open Sans', sans-serif;
          }
        }
        & > .author {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 24px;
          & > p,
          & > a {
            font-family: 'Open Sans', sans-serif;
            text-decoration: none;
            margin: 0px;
            font-size: 14px;
            line-height: 16px;
            color: #0e7d7d;
            font-weight: 500;
          }
        }
        & > .author > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 32px;
          max-width: 32px;
          min-height: 32px;
          max-height: 32px;
          margin-right: 8px;
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 250px;
          }
        }
      }
    }
    &:nth-child(1) {
      margin-top: 0px;
    }
  }
}

@media (max-width: 767px) {
  .comments__list .elem__comment .top__part > .left {
    flex-direction: column;
    align-items: flex-start;
  }
  .comments__list .elem__comment .top__part > .left > .author {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .heading__ .left:last-child {
    margin-bottom: 0px;
  }
}

.comment {
  border: 1px solid #066363;
  background-color: rgba(6, 99, 99, 0.1);
  color: #066363;
}
.poke {
  border: 1px solid #2b0e7d;
  background-color: rgba(43, 14, 125, 0.1);
  color: #2b0e7d;
}
.technical_issue {
  border: 1px solid #bc7e04;
  background-color: rgba(188, 126, 4, 0.1);
  color: #bc7e04;
}
.linguistic_issue {
  border: 1px solid #0050ea;
  background-color: rgba(0, 80, 234, 0.1);
  color: #0050ea;
}
.deadline {
  border: 1px solid #b80000;
  background-color: rgba(184, 0, 0, 0.1);
  color: #b80000;
}
.financials {
  border: 1px solid #00701f;
  background-color: rgba(0, 112, 31, 0.1);
  color: #00701f;
}

.round {
  &.comment {
    border: 1px solid #066363;
    background-color: rgba(6, 99, 99, 0.1);
    color: #066363;
    & > span {
      border: 1px solid #066363;
      & > svg > path {
        stroke: #066363;
      }
    }
  }
  &.poke {
    border: 1px solid #2b0e7d;
    background-color: rgba(43, 14, 125, 0.1);
    color: #2b0e7d;
    & > span {
      border: 1px solid #2b0e7d;
      & > svg > path {
        stroke: #2b0e7d;
      }
    }
  }
  &.technical_issue {
    border: 1px solid #bc7e04;
    background-color: rgba(188, 126, 4, 0.1);
    color: #bc7e04;
    & > span {
      border: 1px solid #bc7e04;
      & > svg > path {
        stroke: #bc7e04;
      }
    }
  }
  &.linguistic_issue {
    border: 1px solid #0050ea;
    background-color: rgba(0, 80, 234, 0.1);
    color: #0050ea;
    & > span {
      border: 1px solid #0050ea;
      & > svg > path {
        stroke: #0050ea;
      }
    }
  }
  &.deadline {
    border: 1px solid #b80000;
    background-color: rgba(184, 0, 0, 0.1);
    color: #b80000;
    & > span {
      border: 1px solid #b80000;
      & > svg > path {
        stroke: #b80000;
      }
    }
  }
  &.financials {
    border: 1px solid #00701f;
    background-color: rgba(0, 112, 31, 0.1);
    color: #00701f;
    & > span {
      border: 1px solid #00701f;
      & > svg > path {
        stroke: #00701f;
      }
    }
  }
}
.jodit-ui-spacer {
  display: none;
}

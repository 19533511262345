.file-drop-dragging-over-frame .upload-content__drag-drop,
.file-drop-dragging-over-frame .upload-content__files {
  border: 3px dashed #7ab8ec;
  background: #fbfdff;
}

.tabs__content,
.modal {
  display: block;
}

.error-border {
  border-color: red !important;
  border: 1px;
  border-style: solid !important;
}

.upload__block .upload-content__files-list .form-group img {
  min-width: 28px;
  height: 30px;
  display: inline-block;
  position: relative;
  z-index: 2;
}

.submit_files_drop {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 160px;
  border-radius: 4px 0px 0px 0px;
  background: #f7f7f7;
  background: #f7f7f7;
}

.submit_files_drop .upload__block p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #444;
  text-align: center;
}

.submit_files_drop .upload__block p span {
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
}

.submit_files_drop .upload__block svg {
  margin-left: auto;
  margin-right: auto;
}

.submit_files_drop .btn-add__icon {
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 4px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBFN0Q3RCIvPgo8L3N2Zz4K')
    center no-repeat;
  transition: all 0.4s ease;
  position: relative;
}

.complex__left .hint__text {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.downdload__complex__boder {
  border-top: 10px solid #ebebef;
}

.not_finished_job {
  display: flex;
  align-items: center;
}

.not_finished_job img {
  width: 73.09px;
  height: 77.8px;
  gap: 0px;
  opacity: 1 !important;
}

.order__table .title__order--table {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 0 15px;
  margin-bottom: 15px;
}

.ask_help_wrap {
  display: flex;
  flex-direction: row-reverse;
}

.ask_help_wrap div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  min-height: 45px;
  border: 0px;
  background: #0e7d7d;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-left: 15px;
  transition: 0.2s ease all;
  cursor: pointer;
}

.ask_help_wrap div:hover {
  background-color: #0a5858;
}

.ask_help_wrap div p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #fff;
}

.table__wrapper
  table.table__common
  .status__table--wrapper
  .status__table--dropdown
  .main__status--table {
  width: auto;
  max-width: 175px;
  /* padding-right: 35px; */
  padding-left: 20px;
  min-width: 175px;
}

.time {
  font-size: 10px;
}

.mr-0 {
  margin-right: 0;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.pd-0 {
  padding: 0px !important;
}

.chat_users_select {
  width: 300px;
}

.d-flex {
  display: flex;
}

.f-col {
  flex-direction: column !important;
}

.chat__tags {
  overflow-x: scroll !important;
}

// .chat__tags::-webkit-scrollbar-track {
//   background: #E9E9E9;
// }

.chat__tags::-webkit-scrollbar {
  height: 0px;
  //border-radius: 3px;
}

.chat__tags div {
  background: #18a6a6;
  padding: 3px 6px 3px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.chat__tags div:not(:last-child) {
  margin-right: 4px;
}

.table__wrapper table.table__common tr th .search__employee {
  left: 0px;
  height: 100%;
  background-color: #fff;
  width: 100%;
  top: -4px;
}

.table__wrapper table.table__common tr th .search__employee > div {
  display: flex;
  align-items: center;
}

.create__info .check__head > .currency__picker .val__currency > input {
  height: 30px;
  max-width: 60px;
  font-size: 12px;
}

.service__name.service__locked > input {
  min-width: 145px;
  height: 30px;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  color: #787878;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 95% center;
  background-color: #f7f7f7;
  padding-right: 30px;
}

.progress_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table__wrapper table.table__common tr th .search__employee > div > input[type='text'] {
  height: 40px;
  width: 100%;
  padding-left: 15px;
  padding-right: 35px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
}

.chat__tags div span {
  font-size: 12px;
  color: white;
}

.chat__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.users__group {
  display: flex;
  width: 300px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.users__group div {
  display: flex;
  flex-direction: column;
}

.users__group div p {
  font-size: 11px;
  text-align: center;
}

/* Track */
.users__group::-webkit-scrollbar-track {
  background: #e9e9e9;
}

.users__group::-webkit-scrollbar {
  height: 3.5px;
  border-radius: 3px;
}

.parents-background {
  background-color: inherit;
}

.non-click {
  pointer-events: none;
}
.all-click {
  pointer-events: all;
}

/* Handle */
.users__group::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}

.choosen__user {
  border: 2px solid #0e7d7d;
}

.finish__btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.loader-box {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.attachment__box {
  width: 100%;
  display: flex;
  align-items: center;
  height: 73px;
  padding-left: 8px;
}

.show__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  min-width: 85px;
  max-width: 85px;
  min-height: 30px;
  cursor: pointer;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}

.show__main.active__expandable {
  background: #0e7d7d !important;
  border: 1px solid #0e7d7d !important;
  border-radius: 4px !important;
}

.show__main.active__expandable path {
  fill: #fff;
}

.show__main.active__expandable span.arrow__show {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.show__main.active__expandable p {
  color: #fff;
}

.show__main svg {
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  position: relative;
  left: 0px;
}

.show__main .arrow__show {
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  margin-left: 10px;
}

.show__main p {
  margin: 0px;
  color: #0e7d7d;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.show__main:hover {
  border-color: #0e7d7d;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.status__table--wrapper .status__table--dropdown .assigned__status {
  border: 1px solid #0e7d7d;
  padding: 5px 15px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 30px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}

.status__table--wrapper .status__table--dropdown .assigned__status p {
  color: #0e7d7d;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.status__table--wrapper .status__table--dropdown .correction__needed__status {
  padding: 5px 15px;
  border: 1px solid #e9a500;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.status__table--wrapper .status__table--dropdown .correction__needed__status p {
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #e9a500;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin: 0px;
}

.status__table--wrapper .status__table--dropdown .waiting__approval__status p {
  color: #e27a00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.status__table--wrapper .status__table--dropdown .waiting__approval__status {
  padding: 5px 15px;
  border: 1px solid #e27a00;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 170px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 30px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}

.status__table--wrapper .status__table--dropdown .not__assigned__status {
  padding: 5px 15px;
  border: 1px solid #3f88c5;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 195px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  min-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.status__table--wrapper .status__table--dropdown .not__assigned__status p {
  color: #3f88c5;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.status__table--wrapper .status__table--dropdown .correction__needed p span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
}

.status__table--wrapper .status__table--dropdown .picked__status {
  padding: 8px 15px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 180px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 30px;
  border: 1px solid #523fc5;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}

.status__table--wrapper .status__table--dropdown .picked__status p {
  margin: 0px;
  color: #523fc5;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.status__table--wrapper .status__table--dropdown .cancelled__status p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ff3232;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.status__table--wrapper .status__table--dropdown .cancelled__status {
  padding: 5px 15px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 30px;
  border: 1px solid #ff3232;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}

.status__table--wrapper .status__table--dropdown .looking_worker__status {
  padding: 5px 15px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 30px;
  /* border: 1px solid #FF3232; */
  background: rgba(250, 230, 253, 1);
  margin-left: auto;
  margin-right: auto;
}

.looking_worker__label {
  color: #c700e7 !important;
  display: flex;
}

.done_btn {
  min-width: 95px;
  min-height: 35px;
  margin-right: 10px;
  background: #0e7d7d;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.done_btn:hover {
  background-color: #0a5858;
}

.done_btn:active {
  color: #ffffff;
  background-color: #094f4f !important;
}

.content__order--settings > div {
  margin-bottom: 7px;
}

.file-upload-package {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  & .close {
    position: absolute;
    left: -20px;
    top: 0;
  }

  &__unit-wrapper {
    width: 100%;
    max-width: 540px;
    margin-right: 20px;
    .file-drop {
      max-width: 540px;
      width: 100%;
      &-target {
        max-width: 540px;
        width: 100%;
      }
    }
  }
}

.file-drop {
  margin-bottom: 20px;
}

.select__dropdown-scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.select__dropdown-scroll::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}

.select__option {
  margin-right: 6px;
}

.complex__right {
  padding-bottom: 0;
}

.table__wrapper
  table.table__common
  .status__table--wrapper
  .status__table--dropdown
  .created__status {
  padding: 5px 15px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 30px;
  /* border: 1px solid #FF3232; */
  background: #e3e3e3;
  margin-left: auto;
  margin-right: auto;
}

.created__label {
  color: #523fc5 !important;
  display: flex;
}

.table__wrapper
  table.table__common
  .status__table--wrapper
  .status__table--dropdown
  .main__status--table
  p
  span {
  margin-right: 10px;
}

/* .table__wrapper table.table__common .status__table--wrapper .status__table--dropdown .cancelled__status p span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 10px;
} */

.table__wrapper
  table.table__common
  .hover__checkbox--number:hover
  .checkbox__number
  .checkbox__w--number
  .check__input {
  opacity: 1;
}

.table__wrapper
  table.table__common
  .hover__checkbox--number:hover
  .checkbox__number
  .checkbox__w--number
  p {
  opacity: 0;
}

.table__wrapper
  table.table__common
  .hover__checkbox--number.active__job
  .checkbox__number
  .checkbox__w--number
  .float__input {
  opacity: 1;
}

.table__wrapper
  table.table__common
  .hover__checkbox--number.active__job
  .checkbox__number
  .checkbox__w--number
  .weight__text {
  opacity: 0;
}

.table__wrapper table.table__common tr td.active--check:before {
  opacity: 1;
  width: 5px;
}

.table__wrapper table.table__common tr td.active--check .check__input {
  opacity: 1 !important;
}

.table__wrapper table.table__common.top__table tr {
  vertical-align: middle;
}

.prefix {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: underline;
  color: #0e7d7d;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  white-space: nowrap;
}

.comment__inner {
  position: fixed;
  display: block;
  /* margin-top: 30px; */
  /*  left: 0px; */
  min-width: 270px;
  max-width: 270px;
  padding: 20px;
  background: #ffffff;
  -webkit-box-shadow: 5px 10px 40px rgb(0 0 0 / 5%);
  box-shadow: 5px 10px 40px rgb(0 0 0 / 5%);
  border-radius: 3px;
  display: none;
  z-index: 3;
}

.add__plan .add__plan--dropdown .submit {
  width: 100%;
  height: 40px;
  border: 2px solid #0e7d7d;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: transparent;
  color: rgba(14, 125, 125, 1);
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  transition: 0.4s ease all;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add__plan .add__plan--dropdown .submit:focus,
.add__plan .add__plan--dropdown .submit:active {
  border-color: rgba(14, 125, 125, 0.3);
}

.select__response--picker {
  /* min-width:310px; */
  right: 0px;
}

.pointer {
  cursor: pointer;
}

.heading__my--projects .projects__dropdown--container {
  position: fixed;
  /* top: 40px; */
  z-index: 2;
  background: #ffffff;
  display: none;
  min-width: 220px;
  max-width: 220px;
  padding: 10px;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  /* left: 50%; */
  transform: translate(-50%, 0);
}

.heading__my--projects .projects__dropdown--container ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  max-height: 200px;
  overflow-y: auto;
}

.heading__my--projects .projects__dropdown--container ul li > a:hover {
  background: #0e7d7d;
  color: #fff;
}

.heading__my--projects .projects__dropdown--container ul li > a {
  padding: 9px 15px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  color: rgba(45, 45, 45, 1);
  font-size: 14px;
  line-height: 21px;
  border-radius: 4px;
  transition: 0.4s ease all;
  font-weight: 400;
}

.heading__my--projects .projects__dropdown--container ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.heading__my--projects .projects__dropdown--container ul::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.heading__my--projects .projects__dropdown--container ul::-webkit-scrollbar-thumb {
  background: #ebebef;
  border-radius: 5px;
}

.heading__my--projects > .projects__main {
  transition: 0.4s ease all;
}

.heading__my--projects > .projects__main:hover {
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
}

.comment__inner .comment__users {
  max-height: 445px;
  overflow-y: auto;
  width: calc(100% + 20px);
  padding-right: 20px;
}

.comment__users .comment__user {
  margin-bottom: 4px;
  border-bottom: 1px solid #e3e3e3;
}

.comment__users .comment__controls {
  position: sticky;
  bottom: 0;
  background: #fff;
}

.comment__inner .comment__users .comment__user .comment__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 8px;
}

.comment__inner .comment__users .comment__user .comment__head > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}

.table__wrapper table.table__common tr td p {
  margin: 0px;
  color: #2d2d2d;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.comment__desc p {
  margin-bottom: 8px !important;
  overflow-wrap: break-word;
}

.order__table {
  padding-bottom: 20px;
  background-color: #fff;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.table__wrapper table.table__common tr th .registration__dropdown > h5 {
  position: absolute;
  min-width: 150px;
  min-height: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: -30px;
  color: #3d3d3d !important;
  font-size: 13px;
  line-height: 17px;
  pointer-events: none;
  font-weight: 400;
  opacity: 0;
  transition: 0.3s ease all;
}

.table__wrapper
  table.table__common
  tr
  th
  .registration__dropdown
  > .registration__button:hover
  > h5 {
  opacity: 1;
  top: -20px;
}

.table__wrapper table.table__common tr th {
  position: relative;
  padding: 25px 15px;
}

.comment__inner .comment__users .comment__user .comment__head > a .comment__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.comment__inner .comment__users .comment__user .comment__head > a .comment__image > img {
  border-radius: 50%;
}

.comment__inner .comment__users .comment__user .comment__head > a > p {
  margin: 0px;
  font-size: 14px;
  color: #2d2d2d;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: underline;
}

.comment__inner .comment__users .comment__user .comment__head > span {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comment__inner .reply__comment input {
  width: 100%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
  color: #000;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}

.comment__inner .comment__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
}

.comment__inner .comment__buttons > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
  width: 100%;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  border: 1px solid #0e7d7d;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #0e7d7d;
  transition: 0.4s ease all;
  border-radius: 3px;
}

.comment__inner .comment__buttons .add-comment {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  color: #fff;
  line-height: 14px;
  font-weight: 600;
  min-height: 40px;
  background: #0e7d7d;
  border: 2px solid #0e7d7d;
  -webkit-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  border-radius: 3px;
}

.comment__inner .comment__users::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.comment__inner .comment__users::-webkit-scrollbar-thumb {
  background: #ebebef;
  border-radius: 5px;
}

.custom__drop form > div[type='submit'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  width: 100%;
  transition: 0.4s ease all;
  border: 2px solid #0e7d7d;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #0e7d7d;
  transition: 0.4s ease all;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
}

.custom__drop form > div[type='submit']:hover {
  background-color: #0e7d7d;
  color: #fff;
}

.inner__modal .row__form.col_4 {
  grid-template-columns: repeat(4, 1fr);
}

.button__label {
  position: relative;
  cursor: pointer;
  padding: 0 0 0 15px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #9d9d9d;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
}

.application-page__form,
.application-form {
  margin-bottom: 2rem;
}

.input-range__input {
  background: #ebebef;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.tag__modal.modal__wrapper > a,
.modal__wrapper.tax__modal > a,
.education__modal.modal__wrapper > a {
  opacity: 1;
}

.react-responsive-modal-container .react-responsive-modal-modal .tag__inner--modal,
.react-responsive-modal-container .react-responsive-modal-modal .tax__inner--modal,
.react-responsive-modal-container .react-responsive-modal-modal .education__inner--modal {
  right: 0;
}

.ReactCollapse--collapse {
  transition: height 1000ms;
}

.menu__main--side > ul li.current__menu > a {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.overflow-visible {
  overflow: visible !important;
}

@media (max-width: 1440px) {
  .overflow-visible {
    overflow: visible !important;
  }
}

.checkbox__input {
  display: block;
  opacity: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: -1;
}

form .tag__search .search__dropdown {
  z-index: 10;
}

.show {
  display: block !important;
}

.table__wrapper table.table__common .opps__deadline {
  margin-left: 0;
  justify-content: flex-start;
}

.table__wrapper
  table.table__common
  .status__table--wrapper
  .status__table--dropdown
  .main__status--table {
  margin-left: 0;
}

.width90 {
  width: 90% !important;
  max-width: 90% !important;
}

.react-responsive-modal-container > .pricing__modal > .inner__modal {
  width: 95%;
  max-width: 100%;
}

.react-responsive-modal-container > .pricing__modal > a {
  right: 95%;
}

.more__combination {
  margin-bottom: 5px;
}

.top__form--pricing > .top__form--head > .top__form--titlelong {
  min-width: 0px;
  width: 140%;
}

.top__form--title:last-child {
  min-width: 195px;
  width: 100%;
}

.lang-combinations-dropdown__box {
  transform: none;
}

.status__hint {
  position: fixed;
  z-index: 65;
  transform: none;
}

.pt-0 {
  padding-top: 0;
}

a {
  cursor: pointer;
}

.other__option--elem > .option__name > .form-group > .input-animation input,
.other__options
  .other__option--wrapper
  .other__option--elem
  > .option__inner--element
  > .option__row
  .option__name
  > .form-group
  > .input-animation
  input {
  min-width: 215px;
  max-width: 215px;
  border: 1px solid #c5c5c5;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background: #f5f5f5;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: none;
}

.other__options
  .other__option--wrapper
  .other__option--elem
  > .option__inner--element
  > .option__desc
  > .form-group
  > textarea {
  max-width: 215px;
  min-width: 215px;
  height: 70px;
  border: 1px solid #c5c5c5;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background: #f5f5f5;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: none;
  resize: none;
  padding-top: 10px;
}

.table__wrapper table.table__common .source__main .source__file > a {
  margin: 0px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}

.source__settings .task__info .head__task {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.table__wrapper table.table__common .expandable__job {
  border-bottom: 1px solid #e3e3e3 !important;
  padding-left: 0 !important;
}

.table__wrapper table.table_nolines td {
  border-bottom: none !important;
}

.table__wrapper table.table__common .file__table tr:last-child td {
  border-bottom: 0;
}

.top__form--pricing > .top__form--head > .top__form--title:nth-child(3) {
  min-width: 140px;
  width: 34%;
}

.size__prize--group .elem__size--prize--group > .wrapper__field:nth-child(1) {
  width: 41%;
}

.job-expand-table .table__wrapper {
  padding-bottom: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.table__wrapper table.table__common .file__table thead > tr > td {
  border-bottom: 1px solid #e3e3e3 !important;
}

.table__wrapper table.table__common .expandable__job {
  border-bottom: 0 !important;
}

.table__wrapper table.table__common.table__lines {
  border-collapse: collapse;
}

.select__dropdown {
  transform: none;
  left: 0;
  max-width: 100%;
}

.th-center table tr th h5 {
  text-align: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.hint__float {
  transform: none;
  top: 100%;
  left: 70%;
  &__top {
    bottom: 120%;
    right: 0px;
    top: unset;
    left: unset;
  }
  &__bottom {
    top: 100%;
    left: 70%;
  }
  &__left {
    top: 20%;
    left: -20%;
  }
  &__right {
    top: 20%;
    right: -250%;
    left: unset;
  }
}

.declarate__size.service__locked > input {
  border: none;
}

.declarate__size.service__locked .select__label {
  border: none;
  background: none;
}

.declarate__size.service__locked .select__label:before {
  content: none;
}

.declarate__size.service__locked .select__label:after {
  content: none;
}

.dropdown__box {
  overflow: unset;
}

.mt-0 {
  margin-top: 0 !important;
}

.float__table--content .droppable__small {
  margin-top: 0;
}

.burger .button__action {
  border: none;
}

.burger .button__action:hover {
  background: unset;
}

.dropdown__box.user-dropdown__box {
  top: 100%;
}

input.disabled {
  pointer-events: none;
}

.submitted__files--employee {
  box-shadow: none;
}

.opps__controls .file__opps a:hover span.archive__opps {
  width: 100%;
}

.education__modal.modal__wrapper > a,
.modal__wrapper.tax__modal > a,
.tag__modal.modal__wrapper > a {
  border: none;
}

.hint__float p {
  white-space: normal;
}

.table__wrapper table.table__common .table__dropdown .select__label {
  width: 80px;
}

.link__style {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

a.disabled {
  opacity: 0.5;
}

.preffered-list__scroll {
  max-height: 240px;
  overflow: scroll;
}

.wrapper__general--info .bank__block {
  max-width: 560px;
}

body > tr {
  display: table !important;
  z-index: 1000;
}

body > tr > td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.side__menu {
  z-index: 10;
}

@media (max-width: 991px) {
  .side__menu.open {
    left: 0;
    z-index: 1002;
  }

  .side__menu {
    z-index: 1002;
  }

  .overlay {
    z-index: 1;
  }

  header .right__header.open {
    top: 0;
  }

  .overlay {
    z-index: 3;
  }
}

.profile__head {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.profile__head > .left__profile {
  width: 30%;
}

.table__common .permissions-control-dropdown__toggle {
  transition: 0.4s ease all;
  box-shadow: none;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 4px;
}

.table__common .permissions-control-dropdown__toggle .burger {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown__box {
  right: unset;
}

.dropdown__box.choose-actions-dropdown__box.permissions-control-dropdown__box {
  right: 0;
}

.text-align-end {
  text-align: end;
}

.form-input .opps__deadline {
  justify-content: flex-start;
}

.source__settings .opps__deadline {
  justify-content: flex-start;
}

.source__settings .opps__deadline > .pointer {
  max-width: 140px;
  overflow: hidden;
}

.source__settings .opps__deadline p {
  font-size: 12px;
}

@media (max-width: 1200px) {
  .source__box {
    flex-direction: column;
  }

  .status__employers .correction__status .correction__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .status__employers .correction__status .correction__info .correction__buttons > a:nth-child(1) {
    max-width: 100%;
    white-space: normal;
  }

  .status__employers .correction__status {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tabs__content,
  .modal {
    overflow: scroll;
  }

  .open__opps > a span {
    opacity: 0 !important;
    display: none;
  }

  .open__opps > a:hover {
    padding-right: 15px;
  }
}

.menu__main--side ul.sidebar-collapse li.droppable__link a .drop__arrow {
  display: none;
}

.menu__main--side ul.sidebar-collapse.open li.droppable__link a .drop__arrow {
  display: block;
}

.table__wrapper table.table__common tr td .permissions__list {
  margin-bottom: 0;
}

.service__name.service__locked.with-title {
  margin-top: 21px;
}

.service__name.service__locked.with-title .service-name-title {
  top: 100%;
  z-index: 9;
}

.notification__messages .head__notification .mark__read > span {
  right: 120%;
  left: unset;
}

.w-100 {
  width: 100% !important;
}

.new__permissions.w-100 > input {
  width: 100% !important;
}

.responsible__list .el__responsible--list.isChild {
  padding-left: 1rem;
}

.heading__standalone > .permissions-page__heading-title {
  display: flex;
  width: 100%;
  align-items: center;
}

.heading__standalone > .permissions-page__heading-title > a {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #0e7d7d;
  border-radius: 4px;
  transition: 0.4s ease all;
  background-color: #0e7d7d;

  & svg path {
    fill: #fff;
  }
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.elem__advanced--picker.service-switch {
  .switch__adv--elem {
    justify-content: flex-end;

    & > label {
      margin: 0;
    }
  }
}

.invoice__total {
  max-width: unset;
  margin-left: -15px;
  margin-top: unset;

  .elem__price {
    font-size: 24px;
    font-weight: bold;
    color: #000;
  }

  .elem__total {
    align-items: center;
    font-weight: bold;
    color: #000;
  }
}

.form-group-head {
  margin-bottom: 16px;

  h6 {
    margin: 0px;
    font-size: 21px;
    color: #000;
    font-weight: bold;
  }
}

form .form-group .table__invoice {
  & .elem__table--invoice {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0px;
    }

    & > p {
      & span {
        font-weight: 600;
        display: inline-flex;
        margin-right: 7px;
      }

      margin: 0px;
      font-size: 14px;
      color: #2d2d2d;
      font-weight: 500;
    }

    justify-content: flex-start;

    & > span {
      font-size: 13px;
      color: grey;
      font-weight: bold;
      min-width: 140px;
    }
  }
}

.invoice__form {
  table.table__common {
    thead {
      tr th {
        h5 {
          margin: 0px;
          font-size: 14px;
          color: #000;
          font-weight: bold;
        }
      }
    }

    & .quantity__ {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > input {
        width: 70px;
        height: 40px;
        text-align: center;
        background-color: #f7f7f7;
      }

      & > a {
        &:nth-child(1) {
          display: inline-flex;
          min-width: 40px;
          max-width: 40px;
          min-height: 40px;
          max-height: 40px;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          background-color: rgba(14, 125, 125, 0.1);

          & span {
            display: inline-flex;
            background-color: rgba(14, 125, 125, 1);
            min-width: 24px;
            min-height: 4px;
            border-radius: 3px;
          }
        }

        &:nth-child(3) {
          display: inline-flex;
          min-width: 40px;
          max-width: 40px;
          min-height: 40px;
          max-height: 40px;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          background-color: rgba(14, 125, 125, 0.1);
          position: relative;

          & span:nth-child(1) {
            display: inline-flex;
            background-color: rgba(14, 125, 125, 1);
            min-width: 24px;
            min-height: 4px;
            border-radius: 3px;
          }

          & span:nth-child(2) {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(14, 125, 125, 1);
            min-height: 24px;
            min-width: 4px;
            position: absolute;

            border-radius: 3px;
          }
        }
      }
    }
  }
}

.permissions__field__form_table {
  & .permissions__field--inner {
    padding: 0px;
    border-color: transparent;
    background-color: #f7f7f7;
  }
}

.certificator__inner {
  width: 100%;
  min-width: 100%;

  label.checkbox {
    margin-top: 1rem;
  }
}

.tags-item {
  max-width: 60%;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 7px 10px;
  background: #18a6a6;
  border-radius: 4px;
  margin-top: 0.5rem;
  text-align: center;
  z-index: 1001;
}

.table-search-wrapper {
  margin: 0 25px;
}

.catalog__right .button__filter.selected {
  color: #fff;
  background-color: #0e7d7d;

  svg {
    fill: #fff;
  }
}

.content__main {
  overflow-y: scroll;
}

.table__messages_icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > div {
    width: 20px;
    position: relative;

    span {
      position: absolute;
      top: -40%;
      right: -20%;
      font-weight: bold;
      font-size: 0.7rem;
      z-index: 23333333;
      background-color: red;
      border-radius: 50%;
      color: white;
      padding: 5%;
      min-width: 1rem;
      text-align: center;
    }
  }
}

.align-self-start {
  align-self: flex-start;
}

.create__settings--inner .inputs__row .settings__info > p {
  margin-top: 0px;
  margin-bottom: 10px;
  color: rgba(45, 45, 45, 1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}

.option__inner--element.suboption,
.other__option--elem.suboption {
  margin-left: 40px;
}

.disabled__finish {
  .btn-add__header {
    box-shadow: inset 0 0 0 1px #c4c4c4, 0 4px 5px rgb(0 0 0 / 5%);

    .btn-add__input-label {
      background: none;
      color: #c4c4c4;
      cursor: default;
      pointer-events: none;
    }
  }
}

.expandable__row.expandable__job > .catalog > .table__wrapper {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.table__wrapper table.table__common.table__lines tr.hideBorderBottom td {
  border-bottom: none;
}

.chat_users_select {
  width: 100%;
  padding-left: 1%;
  padding-right: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2%;

  form {
    width: 84%;
  }

  & .more__search {
    width: 14%;
    position: relative;

    & .search__dropdown {
      position: absolute;
      right: 0px;
      top: 42px;
      min-width: 200px;
      background: #ffffff;
      border: 1px solid #bdbdbd;
      box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 10px;
      z-index: 4;

      & .filter__search {
        & > ul {
          padding: 0px;
          list-style-type: none;
          margin: 0px;

          & > li {
            display: flex;
            width: 100%;

            &.active__filter {
              & > a {
                font-weight: 600;

                &:before {
                  opacity: 1;
                }
              }
            }

            & > a {
              display: flex;
              width: 100%;
              position: relative;

              &:hover {
                &:before {
                  opacity: 1;
                }
              }

              &:before {
                content: '';
                position: absolute;
                left: 10px;
                width: 22px;
                height: 22px;
                background: url('./images/checkfilter.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                opacity: 0;
                transition: 0.4s ease all;
              }

              padding: 7px 10px;
              padding-left: 40px;
              font-size: 13px;
              line-height: 22px;
              font-weight: 400;
              color: #2d2d2d;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }

        & > p {
          margin-top: 0px;
          margin-bottom: 3px;
          font-size: 12px;
          line-height: 25px;
          color: #787878;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      & > ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
        margin-bottom: 3px;

        & > li {
          margin-bottom: 3px;

          & > a {
            font-size: 13px;
            line-height: 22px;
            color: #2d2d2d;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 4px;
            padding: 7px 10px;
            transition: 0.4s ease all;

            &:hover {
              background-color: rgba(239, 239, 239, 1);
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }

    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 32px;
      max-width: 32px;
      min-height: 32px;
      max-height: 32px;
      transition: 0.4s ease all;
      background: #f4f4f4;
      border-radius: 10px;
      border: 1px solid #f4f4f4;

      &.opened__search {
        background-color: #fff;
        border-color: #3f88c5;
      }
    }
  }

  & .group__search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    & > input {
      height: 32px;
      width: 100%;
      padding-left: 40px;
      padding-right: 15px;
      background: #ffffff;
      outline: none;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
    }

    & button {
      min-width: 28px;
      max-width: 28px;
      min-height: 28px;
      max-height: 28px;
      left: 15px;
      position: absolute;
    }
  }
}

.ai-editor .role__controls > input.button {
  max-width: 250px;
}

.button.small-padding {
  padding: 6px 7px;
}

.table__wrapper table.table-stripped.table__common.top__table tbody {
  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }
}

.table-filters .select__dropdown {
  max-width: max-content;
}

.dropdown-section-title {
  padding: 8px 12px;
  color: #666;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f5f5f5;
  pointer-events: none;
}

.dropdown-divider {
  height: 1px;
  margin: 4px 0;
  background-color: #e0e0e0;
  pointer-events: none;
}
